<template>
  <div class="pb-2">
    <div class="teamAddpop d-flex align-items-center justify-content-end">
      <div v-if="addEditRights">
        <span v-b-toggle.addResourceTeamMember>Add Member</span>
      </div>
    </div>
    <div class="team">
      <div class="">
        <div class="d-flex align-items-center justify-content-between">
          <div class="tittle">
            <p class="team-title">
              Management
            </p>
          </div>
          <div class="switch-container d-flex align-items-center ">
            <span class="current-month-text mr-50">Lifetime</span>

            <b-form-checkbox
              v-model="is_current_month"
              class="custom-control-success"
              name="check-button"
              size="md"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon
                  icon=""
                  class="text-success"
                />
              </span>
              <span class="switch-icon-right">
                <feather-icon
                  icon=""
                  class="text-success"
                />
              </span>
            </b-form-checkbox>

            <span class="current-month-text ml-50">Current Month</span>

          </div>
        </div>

        <div class="mt-1">
          <TableLoader
            v-if="loading_team"
            :fields="fields"
          />
          <b-table
            v-else
            responsive
            :items="managemetUsers"
            :fields="fields"
            class="member-table project-team-list"
          >
            <template #cell(member)="data">
              <div class="d-flex align-items-center">
                <div class="user-detail">
                  <p class="user-name">
                    {{ data.item[0].full_name }}
                  </p>
                </div>
                <b-form-checkbox
                  v-model="data.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="data.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">
                    <b-icon
                      icon="Caret-down-fill"
                      size="10"
                    />
                  </span>
                </b-form-checkbox>
              </div>
            </template>
            <template #row-details="data">
              <ul class="teamSubinfo d-flex">
                <li>
                  <span>Month</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.expected_assignment_start_date | monthYearFormat }}
                  </p>
                </li>
                <li>
                  <span>Allocation Date</span>

                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{
                      month.expected_assignment_start_date | DateDDMMYYYYFormat
                    }}
                    to
                    {{
                      month.expected_assignment_end_date | DateDDMMYYYYFormat
                    }}
                  </p>
                </li>
                <li>
                  <span>Hours</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.time_plan ? month.time_plan : 0 }}
                  </p>
                </li>
                <li>
                  <span>Actual Hours</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.allocation_hours ? month.allocation_hours : 0 }}
                  </p>
                </li>
                <li>
                  <span>Productivity(%)</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{
                      (
                        (month.allocation_hours /
                          (month.time_plan ? month.time_plan : 1)) *
                        100
                      ).toFixed() + "%"
                    }}
                  </p>
                </li>
                <li>
                  <span>Allocation</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.assignment ? month.assignment : 0 }}
                  </p>
                </li>

                <!-- TODO: add is billable field-->
                <!-- is_billable start -->
                <li>
                  <span>billable</span>
                  <div class="align-items-center">
                    <p
                      v-for="(month, index) in data.item"
                      :key="index"
                      :class="month.is_billable ? 'billable' : 'nonBillable'"
                    >
                      <b>$</b>
                    </p>
                  </div>
                </li>
                <li
                  v-if="addEditRights"
                  class="ml-5"
                >
                  <span>Action</span>
                  <div>
                    <p
                      v-for="(month, index) in data.item"
                      :key="index"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="edit-icon"
                        size="18"
                        @click="editMember(month)"
                      />
                      <feather-icon
                        icon="Trash2Icon"
                        class="trash-icon"
                        size="18"
                        @click="deleteConfirmation('plan', month)"
                      />
                    </p>
                  </div>
                </li>
              </ul>
            </template>
            <template
              #cell(assignment)="data"
              class="text-center"
            >
              <p class="text-center">
                {{ totalTimePlan(data.item) }}
              </p>
            </template>
            <template #cell(actual)="data">
              <p class="text-center">
                {{ totalActualHours(data.item).toFixed() }}
              </p>
            </template>

            <template #cell(primaryskill)="data">
              <div>
                <div
                  v-if="data.item[0].primary_skills"
                  class="teamSkills d-flex flex-wrap"
                >
                  <b-badge
                    v-for="(skill, index) in data.item[0].primary_skills.split(
                      ','
                    )"
                    :key="index"
                  >{{ skill }}</b-badge>
                </div>
              </div>
            </template>
            <template #cell(assigmentdate)="data">
              {{
                data.item[0].expected_assignment_start_date | DateDDMMYYYYFormat
              }}
            </template>
            <!-- current mohth -->
            <template
              #cell(currentmonth)="data"
              class="text-center"
            >
              <p class="text-center">
                {{ getCurrentMonthAssignment(data.item) }}
              </p>
            </template>

            <!--project user delete-->
            <template #cell(action)="data">
              <span
                v-if="addEditRights"
                class="justify-content-center"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Delete'"
                  icon="Trash2Icon"
                  class="trash-icon"
                  size="18"
                  @click="deleteConfirmation('user', data.item[0].user_id)"
                />
              </span>
            </template>
          </b-table>
        </div>
      </div>

      <!-- team listing complete here-->
    </div>
    <div
      v-if="!loading_team"
      class="team mt-2 mb-5"
    >
      <div class="">
        <div class="tittle">
          <p class="team-title">
            Team Members
          </p>
        </div>
        <div class="mt-1">
          <b-table
            responsive
            :items="memberUsers"
            :fields="fields"
            class="member-table project-team-list"
          >
            <template #cell(member)="data">
              <div class="d-flex align-items-center">
                <div class="user-detail">
                  <p class="user-name">
                    {{ data.item[0].full_name }}
                  </p>
                </div>
                <b-form-checkbox
                  v-model="data.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="data.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">
                    <b-icon
                      icon="Caret-down-fill"
                      size="10"
                    />
                  </span>
                </b-form-checkbox>
              </div>
            </template>

            <template #row-details="data">
              <ul class="teamSubinfo d-flex">
                <li>
                  <span>Month</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.expected_assignment_start_date | monthYearFormat }}
                  </p>
                </li>
                <li>
                  <span>Allocation Date</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{
                      month.expected_assignment_start_date | DateDDMMYYYYFormat
                    }}
                    to
                    {{
                      month.expected_assignment_end_date | DateDDMMYYYYFormat
                    }}
                  </p>
                </li>
                <li>
                  <span>Hours</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.time_plan ? month.time_plan : 0 }}
                  </p>
                </li>
                <li>
                  <span>Actual Hours</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.allocation_hours ? month.allocation_hours : 0 }}
                  </p>
                </li>
                <li>
                  <span>Productivity</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{
                      (
                        (month.allocation_hours /
                          (month.time_plan ? month.time_plan : 1)) *
                        100
                      ).toFixed() + "%"
                    }}
                  </p>
                </li>
                <li>
                  <span>Allocation</span>
                  <p
                    v-for="(month, index) in data.item"
                    :key="index"
                  >
                    {{ month.assignment ? month.assignment : 0 }}
                  </p>
                </li>
                <!-- TODO: add is billable field-->
                <!-- is_billable start -->
                <li>
                  <span>billable</span>
                  <div class="align-items-center">
                    <p
                      v-for="(month, index) in data.item"
                      :key="index"
                      :class="month.is_billable ? 'billable' : 'nonBillable'"
                    >
                      <b>$</b>
                    </p>
                  </div>
                </li>
                <!-- is_billable end -->
                <li
                  v-if="addEditRights"
                  class="ml-5"
                >
                  <span>Action</span>
                  <div>
                    <p
                      v-for="(month, index) in data.item"
                      :key="index"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="edit-icon"
                        size="18"
                        @click="editMember(month)"
                      />
                      <feather-icon
                        icon="Trash2Icon"
                        class="trash-icon"
                        size="18"
                        @click="deleteConfirmation('plan', month)"
                      />
                    </p>
                  </div>
                </li>
              </ul>
            </template>
            <template
              #cell(assignment)="data"
              class="text-center"
            >
              <p class="text-center">
                {{ totalTimePlan(data.item) }}
              </p>
            </template>
            <template #cell(actual)="data">
              <p class="text-center">
                {{ totalActualHours(data.item).toFixed() }}
              </p>
            </template>

            <template #cell(primaryskill)="data">
              <div>
                <div
                  v-if="data.item[0].primary_skills"
                  class="teamSkills d-flex flex-wrap"
                >
                  <b-badge
                    v-for="(skill, index) in data.item[0].primary_skills.split(
                      ','
                    )"
                    :key="index"
                  >{{ skill }}</b-badge>
                </div>
              </div>
            </template>
            <template #cell(assigmentdate)="data">
              {{
                data.item[0].expected_assignment_start_date | DateDDMMYYYYFormat
              }}
            </template>

            <!-- current month -->
            <template
              #cell(currentmonth)="data"
              class="text-center"
            >
              <p class="text-center">
                {{ getCurrentMonthAssignment(data.item) }}
              </p>
            </template>

            <template #cell(action)="data">
              <span
                v-if="addEditRights"
                class="justify-content-center"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Delete'"
                  icon="Trash2Icon"
                  class="trash-icon"
                  size="18"
                  @click="deleteConfirmation('user', data.item[0].user_id)"
                />
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <AddMember
      ref="addResourceTeamMember"
      :teams="projectDtl && projectDtl.project_user ? projectDtl.project_user : []
      "
      :create-type="'project_user'"
    />
  </div>
</template>

<script>
import {
  VBToggle, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import { eventBus } from '@/main'
import moment from 'moment'
import AddMember from './AddTeamMember&ResourcePlan.vue'
import TableLoader from '../../../components/loaders/table-loading.vue'

export default {
  name: 'TeamList',
  components: {
    BTable,
    BFormCheckbox,
    TableLoader,
    AddMember,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    skillString(values) {
      if (values && values.length) {
        const skills = []
        values.forEach(element => {
          skills.push(element.name)
        })
        return skills.join(', ')
      }
      return '-'
    },
  },

  props: {
    projectDtl: {
      type:Object,
      required: true,
      default: () => {}
    },
  },
  data() {
    return {
      loading_team: true,
      id: null,
      // data: null,
      teams: [],

      fields: [
        {
          key: 'member',
          label: 'Team member',
        },
        {
          key: 'currentmonth',
          label: 'Current Month Allocation (%)',
        },
        {
          key: 'assignment',
          label: 'Total Assignment (Hours)',
        },
        {
          key: 'actual',
          label: 'Total Actual Hours',
        },
        {
          key: 'assigmentdate',
          label: 'Assignment Date',
          tdClass: 'member-table-td',
        },
        {
          key: 'primaryskill',
          label: 'Skill',
          tdClass: 'member-table-td',
        },

      ],
      managemetUsers: [],
      memberUsers: [],
      allUser: [],
      is_current_month: this.$route.query.showcurrentmonth ?? true,
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo?.role === 'SA') {
        return true
      }

      const user = this.projectDtl?.project_user?.find(
        pu => pu.id === this.userInfo.id,
      )
      return user?.access_rights && user?.access_rights != 'member' || false
    },

  },
  watch: {
    currentPage: {
      handler() { },
    },
    is_current_month(nv) {
      const query = {
        ...this.$route.query,
        showcurrentmonth: nv,
      }
      this.routeQueryUpdate(query)
      this.projectDetail()
    },
  },
  mounted() {
    const queryParams = this.$route.query
    // Check if a specific query parameter exists
    this.is_current_month = queryParams.showcurrentmonth ? queryParams.showcurrentmonth === 'true' : this.is_current_month
    this.tableField()
    this.projectDetail()
  },
  methods: {
    tableField() {
      if (this.addEditRights || this.userInfo.role == 'SA') {
        this.fields.push({
          key: 'action',
          label: 'Action',
          sortable: false,
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
          tdClass: 'text-right',
        })
      }
    },
    editMember(data) {
      eventBus.$emit('edit-member', data)
      this.$root.$emit('bv::toggle::collapse', 'addResourceTeamMember')
    },
    /**
     * confirmation to delete user
     */
    deleteConfirmation(type, id) {
      this.$swal({
        title: 'Are you sure you want to delete this Record?',
        icon: 'info',
        html: '<p>You won’t be able to revert this!</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (type == 'plan') {
            this.deleteMemeber(id.id)
          } else {
            this.deleteProjectUser(id)
          }
        }
      })
    },
    /**
     * delete client
     */
    async deleteMemeber(id) {
      const input = {
        project_id: this.$route.params.id3,
      }
      const response = await this.getHTTPPostResponse(
        `project/user/delete/${id}`,
        input,
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('reloadProjectDtl', true)
      }
    },

    /**
     * delete client
     */
    async deleteProjectUser(id) {
      const input = {
        project_id: this.$route.params.id3,
        user_id: id,
        // month: moment(data.assignment_start_date).format("MM"),
        // year: moment(data.assignment_start_date).format("YYYY"),
      }
      const response = await this.getHTTPPostResponse(
        'project/user/disable-member',
        input,
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('reloadProjectDtl', true)
      }
    },
    /**
     * get project user
     */
    async projectDetail() {
      this.loading_team = true
      this.users = []
      const input = {
        project_id: this.$route.params.id3,
        type: 'teamList',
        is_current_month: this.is_current_month == true,
      }
      const response = await this.getHTTPPostResponse(
        'project/user/project-user',
        input,
        false,
      )
      if (response && response.status == 200) {
        this.allUser = response.data.project_users

        // sort user data
        const users = [...this.allUser]
        users.sort((a, b) => a.full_name.localeCompare(b.full_name))

        // TODO:: set user data as group by to user full name
        const managementUsers = {}
        const memberUsers = {}

        users.forEach(data => {
          const groupKey = data.full_name
          if (!managementUsers[groupKey]) {
            managementUsers[groupKey] = []
          }
          if (!memberUsers[groupKey]) {
            memberUsers[groupKey] = []
          }

          if (data.access_rights == 'member' || data.access_rights == null) {
            memberUsers[groupKey].push(data)
          } else {
            managementUsers[groupKey].push(data)
          }
        })

        /* before set array value clear variable */
        this.managemetUsers = []
        this.memberUsers = []

        Object.values(managementUsers).forEach(element => {
          if (element.length > 0) this.managemetUsers.push(element)
        })

        Object.values(memberUsers).forEach(element => {
          if (element.length > 0) this.memberUsers.push(element)
        })

        //
        // }
        this.tableField() // temp
        this.loading_team = false
      }
    },
    totalTimePlan(data) {
      let total = 0
      data.forEach(element => {
        total += element.time_plan ? element.time_plan : 0
      })

      return total
    },
    totalActualHours(data) {
      let total = 0
      data.forEach(element => {
        total += element.allocation_hours
          ? parseInt(element.allocation_hours)
          : 0
      })

      return total
    },
    getCurrentMonthAssignment(data) {
      let currentmonth = 0
      if (data && data.length) {
        currentmonth = data.find(
          c => moment(c.expected_assignment_start_date).format('MM-YYYY')
            == moment(new Date()).format('MM-YYYY'),
        )
      }
      return currentmonth ? currentmonth.assignment : 0
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/report.css";

th {
  &:nth-child(2) {
    text-align: center;
  }

  &:nth-child(3) {
    text-align: center;
  }

  &:nth-child(4) {
    text-align: center;
  }

}
</style>
