<template>
  <!-- this from use in role and client table -->
  <div>
    <validation-observer ref="prRequestObserver">
      <b-sidebar
        id="addEditRequest"
        ref="addEditRequest"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="clearData()"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Request
        </h3>
        <div class="line user-underline" />

        <!--Title start-->
        <b-form-group
          label="Title"
          label-for="clientname"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Title"
            rules="required|max:512"
          >
            <b-form-input
              id="name"
              v-model="title"
              :state="errors.length > 0 ? false : null"
              placeholder="Title"
              name="clientname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Title end-->

        <!-- platform-->
        <b-form-group
          label="PR Link"
          label-for="PR Link"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="PR Link"
            rules="required|url"
          >
            <b-form-input
              id="Platform"
              v-model="platform"
              :state="errors.length > 0 ? false : null"
              placeholder="Enter PR Link "
              name="PR Link"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="estimated_start_date">Date <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            name="Date"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="date"
                class="form-control"
                :config="configEstimatedStartDate"
                placeholder="Date"
                name="estimated_start_date"
              />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="estimated_start_date">Time <span class="text-danger">*</span></label>
          <validation-provider
            #default="{ errors }"
            name="Time"
            rules="required"
          >
            <b-form-input
              id="myInput"
              v-model="time"
              class="select-time"
              :style="$store.state.app.isFullScreenModal ? 'text-align: start !important;' : null"
              name="start_time"
              type="time"
              placeholder="03:29 PM"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="estimated_start_date">Description</label>
            <b-form-textarea
              id="myInput"
              v-model="description"
              class="select-time"
              :style="$store.state.app.isFullScreenModal ? 'text-align: start !important;' : null"
              name="start_time"
              placeholder="Enter Description"
            />
        </b-form-group>

        <template #footer>
          <!--form footer start-->
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
              <!-- v-if="btnLoader" -->
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData()"
            >
              Clear
            </button>
          </div>
          <!--form footer end-->
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    // VSelect,
    flatPickr,
  },
  data() {
    return {
      id: null,
      description:null,
      title: null,
      btnLoader: false,
      country: null,
      countries: [],
      date: moment().format('YYYY-MM-DD'),
      configEstimatedStartDate: {
        maxDate: new Date(),
        dateFormat: 'Y/m/d',
      },
      time: moment().format('HH:mm'),
      platform: null,

    }
  },
  methods: {

    /**
       * check the form validation
       * @returns if success true then call addPrRequest Method
       */
    validationForm() {
      this.$refs.prRequestObserver.validate().then(success => {
        if (success) {
          this.addPrRequest()
        }
      })
    },

    /**
       * Add & Update the clientTableForm details & call client listing in clienttable
       * @emits clientList
       * @returns client detail add & update
       */
    async addPrRequest() {
      this.btnLoader = true
      let input = {
        title: this.title,
        date: this.date,
        project_id: this.$route.params.id3,
        time: this.time,
        platform: this.platform,
        description:this.description
      }
      const response = await this.getHTTPPostResponse(
        'project/pr-request/create',
        input,
        true,
      )

      if (response && response.status === 200) {
        input = {
          page: 1,
        }
        this.$emit('reloadList', true)
        this.$root.$emit('bv::toggle::collapse', 'addEditRequest')
      }
      setTimeout(() => {
        this.btnLoader = false
      }, 200)
    },

    /**
       *  Clear form details
       */
    clearData() {
      this.id = null
      this.title = null
      this.date = moment().format('YYYY-MM-DD')
      this.time = moment().format('HH:mm')
      this.platform = null
      this.description = null
      this.$refs.prRequestObserver.reset()
    },
  },
}
</script>